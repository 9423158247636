<template>
  <div>
    <!-- <div class="bread-crumbs" style="margin-bottom: 10px">
      首页 &gt; 联系我们
    </div> -->
    <div class="title">
      <h4>联系我们</h4>
    </div>
    <div class="txt txt1">
      <p></p>
      <p style="line-height: 2em">
        <span style="color: #c10000"
          ><strong><span style="font-size: 14px"></span></strong
        ></span>
      </p>

      <p style="line-height: 2em">
        <span
          style="
            box-sizing: border-box;
            color: rgb(84, 141, 212);
            font-size: 14px;
          "
          ><strong
            ><br /><span
              style="font-size: 14px; box-sizing: border-box; color: #c10000"
              >北京医学会</span
            ></strong
          ></span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="box-sizing: border-box; font-size: 14px; color: rgb(0, 0, 0)"
          >于银林&nbsp;</span
        >
      </p>
      <p style="line-height: 2em">
        <span
          style="
            background-color: rgb(255, 255, 255);
            font-size: 14px;
            color: rgb(0, 0, 0);
          "
          >固话：010-65252365</span
        >
      </p>

      <p style="line-height: 2em">
        <span style="color: #c10000; font-size: 14px"
          ><span style="font-size: 14px"><br /></span
          ><strong><span style="color: #c10000">注册联系人</span></strong></span
        >
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)">刘欣竹</span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >固话：010-58138086<br
        /></span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >手机：19910216241</span
        >
      </p>

      <p style="line-height: 2em">
        <span style="font-size: 14px"
          ><span style="font-size: 14px"><br /></span
          ><span style="font-size: 14px; color: #c10000"
            ><strong>企业联系人</strong></span
          ></span
        >
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)">黄小慧&nbsp;</span>
      </p>
      <p style="line-height: 2em">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >手机：15801500248</span
        >
      </p>

      <p
        style="
          white-space: normal;
          box-sizing: border-box;
          margin: 0px;
          padding: 0px;
          color: rgb(121, 121, 121);
          font-size: 16px;
          background-color: rgb(255, 255, 255);
          line-height: 2em;
        "
      >
        <span
          style="box-sizing: border-box; font-size: 14px; color: rgb(0, 0, 0)"
          ><br
        /></span>
      </p>
      <p style="white-space: normal"><br /></p>
      <p style="line-height: 2em">
        <span style="color: rgb(0, 0, 0); font-size: 14px"></span
        ><span style="font-size: 14px; color: rgb(0, 0, 0)"><br /></span><br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  padding: 25px 30px;
}
</style>